import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import Particle from "../Particle";


function Imprint() {
    return (
        <Container fluid className="about-section">
            <Particle/>
            <Container>
                <Row style={ { justifyContent: "center", padding: "10px" } }>
                    <Col
                        md={ 7 }
                        style={ {
                            justifyContent: "center",
                            paddingTop: "30px",
                            paddingBottom: "50px",
                        } }
                    >
                        <h1 style={ { fontSize: "2.1em", paddingBottom: "20px" } }>
                            Impressum
                        </h1>
                    </Col>
                    <Col
                        md={ 5 }
                        style={ { paddingTop: "120px", paddingBottom: "50px" } }
                        className="about-img"
                    >
                        <div className='impressum'><h1>Impressum</h1><p>Angaben gemäß § 5 DDG</p><p>Jan Heidel <br/>
                            Buchenweg 52<br/>
                            52382 Niederzier <br/>
                        </p><p><strong>Vertreten durch: </strong><br/>
                            Jan Heidel<br/>
                        </p><p><strong>Kontakt:</strong> <br/>
                            Telefon: 2428-5096444<br/>
                            E-Mail: <a href='mailto:Jan.heidel@it-favour.de'>Jan.heidel@it-favour.de</a></p>
                        </div>


                    </Col>
                </Row>

            </Container>
        </Container>
    )
        ;
}

export default Imprint;
