import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, mein name ist <span className="purple">Jan Heidel </span>
            aus <span className="purple"> Niederzier, Deutschland</span>
            <br />
            Zur Zeit bin ich Schüler und besuche die Oberstufe.

            <br />
            Neben dem Programmieren habe ich auch andere Aktivitäten in meiner Freizeit.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Tennis spielen
            </li>
            <li className="about-activity">
              <ImPointRight /> Mit Freunden treffen
            </li>
          </ul>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
