import React from "react";
import { Col, Row } from "react-bootstrap";

import {
    DiJavascript1,
    DiReact,

    DiPython,
    DiGit, DiPhp,

} from "react-icons/di";
import {
    SiFirebase, SiNextdotjs,
    SiRust,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
          <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiPhp />
        </Col>

    </Row>
  );
}

export default Techstack;
