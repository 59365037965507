import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import tennistastic from "../../Assets/Screenshot_20231208_205401.png"


function Projects() {
    return (
        <Container fluid className="project-section">
            <Particle/>
            <Container>
                <h1 className="project-heading">
                    Meine<strong className="purple"> Projekte</strong>
                </h1>
                <p style={{color: "white"}}>
                    Hier sind ein paar meiner Projekte.
                </p>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={tennistastic}
                            isBlog={false}
                            title="Tennistastic"
                            description="Eine simple methode Tennis plätze zu buchen und zu verwalten ausßerdem bietet es die möglich keit das Vereinstraining zu verwalten."
                            demoLink="https://tennistastic.de/"
                        />
                    </Col>

                </Row>
            </Container>
        </Container>
    );
}

export default Projects;
