import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Darf ich  <span className="purple"> mich</span> vorstellen
            </h1>
            <p className="home-about-body">
              Ich liebes zu programmieren, dabei habe ich viel gelernt. Hoffe ich  🤷‍♂️
              <br />
              <br />Meine lieblings Sprachen sind
              <i>
                <b className="purple"> Php und Python </b>
              </i>
              <br />
              <br />
              Meine interessen sind &nbsp;
              <i>
                <b className="purple">Neue Websites zu erstellen </b> aber
                auch das Thema {" "}
                <b className="purple">
                  Cyber-Security &nbsp;
                </b>
                 interessiert mich sehr.
              </i>
              <br />
              <br />
              Wenn möglich versuche ich &nbsp;
             <b className="purple">Symfony</b> und
              <i>
                <b className="purple">
                  {" "}
                  Moderne Javascript Frameworks
                </b>
              </i>
              &nbsp; wie
              <i>
                <b className="purple"> React.js </b>
              </i>zu verwenden.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

      </Container>
    </Container>
  );
}
export default Home2;
